import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.1.4_next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.1.4_next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.1.4_next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"preload\":true,\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin-ext\"],\"variable\":\"--font-lato\",\"display\":\"swap\",\"fallback\":[\"Arial\"]}],\"variableName\":\"lato\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"preload\":true,\"weight\":[\"400\"],\"style\":[\"italic\"],\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-playfair-display\",\"fallback\":[\"Georgia\"]}],\"variableName\":\"playfairDisplay\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/propensity-tag.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/external-links/external-links.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/hotjar/hotjar.tsx")