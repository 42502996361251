"use client";
import { useEffect, useRef } from "react";

const useExternalLinks = () => {
  const lastPageHandled = useRef<string | null>(null);
  useEffect(() => {
    const handleExternalLinks: MutationCallback = (mutationsList, observer) => {
      if (lastPageHandled.current === window.location.href) {
        return;
      }
      lastPageHandled.current = window.location.href;

      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const addedNodes = mutation.addedNodes;
          for (const node of addedNodes) {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const links = (node as HTMLElement).querySelectorAll("a");
              links.forEach((link) => {
                if (link.host !== window.location.host) {
                  link.setAttribute("target", "_blank");
                  link.setAttribute("rel", "noopener noreferrer");
                }
              });
            }
          }
        }
      }
    };

    const observer = new MutationObserver(handleExternalLinks);
    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(document.body, observerOptions);

    return () => {
      observer.disconnect();
    };
  });
};

export default useExternalLinks;
