"use client";

import Script from "next/script";

export function PropensityTag() {
  return (
    <Script
      src="https://cdn.propensity.com/propensity/propensity_analytics.js"
      strategy="afterInteractive"
      crossOrigin="anonymous"
      onLoad={() => {
        if (typeof window.propensity === "function") {
          window.propensity("propensity-003489");
        } else {
          console.error("Propensity function not available");
        }
      }}
    />
  );
}
