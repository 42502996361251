"use client";

import { useEffect } from "react";

import HotjarClient from "@hotjar/browser";

const HotJar = () => {
  useEffect(() => {
    HotjarClient.init(
      +process.env.NEXT_PUBLIC_HOTJAR_ID!,
      +process.env.NEXT_PUBLIC_HOTJAR_VERSION!
    );
  }, []);

  return null;
};

export { HotJar };
